import React from 'react';

import useStyles from './styles';

interface Props {
    variant: 'danger' | 'info' | 'success';
}

const Circle: React.FC<Props> = ({ variant }) => {
    const { classes, cx } = useStyles();
    return (
        <span
            className={cx(classes.root, {
                [classes.danger]: variant === 'danger',
                [classes.info]: variant === 'info',
                [classes.success]: variant === 'success'
            })}
        />
    );
};

export default Circle;
