import { makeStyles } from 'tss-react/mui';

import { chipClasses } from '@mui/material';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Header' })((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '24px 0',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            padding: '8px 0'
        }
    },
    flex: {
        flex: 1
    },
    logo: {
        marginRight: 24
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    login: {
        padding: '12px 16px',
        border: '1px solid #DEE1E2',
        boxShadow: 'none !important',
        [`& .${chipClasses.label}`]: {
            color: '#232323',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            padding: 0
        },
        [`& .${chipClasses.icon}`]: {
            marginLeft: -9,
            marginRight: 4
        }
    },
    demo: {
        padding: '12px 16px',
        background: '#fff',
        boxShadow: 'none !important',
        [`& .${chipClasses.label}`]: {
            color: '#232323',
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            padding: 0
        }
    }
}));
