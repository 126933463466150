import React from 'react';

import useStyles from './styles';

interface Props {
    title: string;
    subtitle: string;
    className?: string;
}

const ApiAdvertisingTitle: React.FC<Props> = ({ title, subtitle, className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subtitle}>{subtitle}</span>
        </div>
    );
};

export default ApiAdvertisingTitle;
