import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import useStores from 'hooks/use-stores';

import ClockIcon from './assets/clock.svg';
import CodeIcon from './assets/code.svg';
import ListIcon from './assets/list.svg';
import PlusIcon from './assets/plus.svg';
import ShieldIcon from './assets/shield.svg';
import TetrisIcon from './assets/tetris.svg';
import InfoGraphicItem from './components/InfoGraphicItem';
import useStyles from './styles';

import Carousel from '../Carousel';

interface Props {
    className?: string;
}

const InfoGraphic: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    const { statsStore } = useStores();

    useEffect(() => {
        statsStore.loadStats();
        statsStore.loadBulletinStats();
    }, [statsStore]);

    return (
        <Carousel className={cx(classes.carousel, className)} gap={16} direction="ltr">
            <InfoGraphicItem
                icon={<PlusIcon />}
                loading={statsStore.statsLoading}
                value={Math.round(statsStore.stats.avgCvePerDay ?? 0)}
                description="average CVE added per day"
            />
            <InfoGraphicItem
                icon={<CodeIcon />}
                loading={statsStore.statsLoading}
                value={Math.round((statsStore.stats.exploits ?? 0) / 1_000)}
                unit="k+"
                description="exploits for popular software and systems"
            />
            <InfoGraphicItem
                icon={<ShieldIcon />}
                loading={statsStore.loading}
                value={Math.round((statsStore.total.totalHits ?? 0) / 1_000_000)}
                unit="mln+"
                description="security advisories and bulletins"
            />
            <InfoGraphicItem
                icon={<ListIcon />}
                loading={statsStore.statsLoading}
                value={Math.round(statsStore.stats.avgBulletinsPerDay ?? 0)}
                description="average entries added per day"
            />
            <InfoGraphicItem
                icon={<ClockIcon />}
                loading={statsStore.statsLoading}
                value={Math.round(statsStore.stats.avgExploitPublicationDate / 3600)}
                unit="hours"
                description="average hours to exploit published"
            />
            <InfoGraphicItem
                icon={<TetrisIcon />}
                loading={statsStore.statsLoading}
                value={statsStore.stats.collections}
                unit="+"
                description="software vendors and other security sources"
            />
        </Carousel>
    );
};

export default observer(InfoGraphic);
