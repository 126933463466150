import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'InsightsItem' })({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    primary: {
        color: '#232323',
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '140%'
    },
    secondary: {
        color: '#8E8E8E',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '150%'
    }
});
