import React from 'react';

import SubMenuItem from 'pages/landing/v2/Main/components/MenuMobile/components/SubMenuItem';

import PluginsIcon from './assets/plugins.svg';
import useStyles from './styles';

import SubMenuGroup from '../SubMenuGroup';

interface Props {
    className?: string;
}

const SubMenu: React.FC<Props> = ({ className, children }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            {children}
            <SubMenuGroup>
                <SubMenuItem
                    title="Enhance security?"
                    description="Browse all"
                    link={'/plugins'}
                    icon={<PluginsIcon />}
                />
                <SubMenuItem
                    title="Contact us"
                    description="Know more about vulners"
                    link={'/company/contacts'}
                    icon={<PluginsIcon />}
                />
            </SubMenuGroup>
        </div>
    );
};

export default SubMenu;
