import React from 'react';

import FeatureCard from 'pages/landing/v2/Main/components/Features/components/FeatureCard';

import feature_1 from './assets/feature_1.png?file';
import feature_2 from './assets/feature_2.png?file';
import feature_3 from './assets/feature_3.png?file';
import useStyles from './styles';

interface Props {
    className?: string;
}

const Features: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <h1 className={classes.title}>
                {'At Vulners core is a graph database'}
                <br />
                {'with a robust API allowing'}
            </h1>
            <div className={classes.features}>
                <FeatureCard
                    image={feature_1}
                    description="Precise search of vulnerabilities by CPE, name/version, or using full-text search"
                />
                <FeatureCard
                    image={feature_2}
                    description="Getting all metrics like CVSS, EPSS,Vulners AI Score, CWE, exploited in the wild, and more with one call"
                />
                <FeatureCard
                    image={feature_3}
                    description="Retrieving interlinked information about exploits, news, and blog posts"
                />
            </div>
        </div>
    );
};

export default Features;
