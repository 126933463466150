export enum ReferenceType {
    API = 'api',
    PYTHON = 'python',
    RESPONSE = 'response'
}

export interface Case {
    title: string;
    data: Record<ReferenceType, string>;
}
