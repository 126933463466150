import React from 'react';

import useStyles from './styles';

interface Props {
    title: React.ReactNode;
    selected?: boolean;
    className?: string;
    onClick?: () => void;
}

const TabItem: React.FC<Props> = ({ title, selected, onClick, className }) => {
    const { classes, cx } = useStyles();
    return (
        <span
            onClick={onClick}
            className={cx(
                classes.root,
                {
                    [classes.selected]: selected
                },
                className
            )}
        >
            {title}
        </span>
    );
};

export default TabItem;
