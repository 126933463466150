import React, { useState } from 'react';

import Hljs from 'main/components/Hljs';

import cases from './cases';
import CaseItem from './components/CaseItem';
import Circle from './components/Circle';
import CopyButton from './components/CopyButton';
import TabItem from './components/TabItem';
import useStyles from './styles';
import { Case, ReferenceType } from './types';

interface Props {
    className?: string;
}

const ApiReference: React.FC<Props> = ({ className }) => {
    const { cx, classes } = useStyles();

    const [referenceType, setReferenceType] = useState(ReferenceType.API);
    const [activeCase, setActiveCase] = useState<Case>(cases[0]);

    const text = activeCase.data[referenceType];
    const language = {
        [ReferenceType.API]: 'bash',
        [ReferenceType.PYTHON]: 'python',
        [ReferenceType.RESPONSE]: 'json'
    }[referenceType];

    return (
        <div className={cx(classes.root, className)}>
            <h1 className={classes.title}>{'See how our API works — examples of queries with real responses'}</h1>
            <div className={classes.content}>
                <div className={classes.references}>
                    <div className={classes.line}>
                        <div className={classes.circles}>
                            <Circle variant="danger" />
                            <Circle variant="info" />
                            <Circle variant="success" />
                        </div>
                        <CopyButton value={text} />
                    </div>
                    <div className={classes.code}>
                        <Hljs language={language}>{text}</Hljs>
                    </div>
                    <div className={classes.line}>
                        <div className={classes.tabs}>
                            <TabItem
                                title={'API'}
                                onClick={() => setReferenceType(ReferenceType.API)}
                                selected={referenceType === ReferenceType.API}
                            />
                            <TabItem
                                title={'Python'}
                                onClick={() => setReferenceType(ReferenceType.PYTHON)}
                                selected={referenceType === ReferenceType.PYTHON}
                            />
                            <TabItem
                                title={'Response'}
                                onClick={() => setReferenceType(ReferenceType.RESPONSE)}
                                selected={referenceType === ReferenceType.RESPONSE}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.cases}>
                    {cases.map((item, index) => (
                        <CaseItem
                            key={index}
                            index={index + 1}
                            title={item.title}
                            selected={item === activeCase}
                            onClick={() => setActiveCase(item)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ApiReference;
