import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'Circle' })({
    root: {
        display: 'inline-block',
        width: 16,
        height: 16,
        borderRadius: 8
    },
    danger: {
        background: '#ff5f57'
    },
    info: {
        background: '#febc2e'
    },
    success: {
        background: '#28c840'
    }
});
