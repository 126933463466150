import React from 'react';

type Props = React.HTMLProps<HTMLPreElement>;

const CodeBlock: React.ForwardRefRenderFunction<HTMLPreElement, Props> = ({ children, ...props }, ref) => {
    return (
        <pre ref={ref}>
            <code {...props}>{children}</code>
        </pre>
    );
};

export default React.forwardRef(CodeBlock);
