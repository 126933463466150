import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import ChatIcon from './assets/chat.svg';
import PluginsIcon from './assets/plugins.svg';
import useStyles from './styles';

import SubMenuGroup from '../SubMenuGroup';

interface Props {
    className?: string;
}

const SubMenu: React.FC<PropsWithChildren<Props>> = ({ className, children }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            {children}
            <SubMenuGroup className={classes.actions}>
                <Link className={classes.action} to="/plugins">
                    <PluginsIcon />
                    <div className={classes.text}>
                        <div className={classes.title}>{'Enhance security?'}</div>
                        <div className={classes.subtitle}>{'Browse all'}</div>
                    </div>
                </Link>
                <Link className={classes.action} to="/company/contacts">
                    <ChatIcon />
                    <div className={classes.text}>
                        <div className={classes.title}>{'Contact us'}</div>
                        <div className={classes.subtitle}>{'Know more about vulners'}</div>
                    </div>
                </Link>
            </SubMenuGroup>
        </div>
    );
};

export default SubMenu;
