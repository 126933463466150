import React from 'react';

import useStyles from './styles';

interface Props {
    icon: React.ReactNode;
    title: string;
    description: string;
    className?: string;
}

const ApiAdvertisingItem: React.FC<Props> = ({ icon, title, description, className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <span className={classes.icon}>{icon}</span>
            <span className={classes.title}>{title}</span>
            <span className={classes.description}>{description}</span>
        </div>
    );
};

export default ApiAdvertisingItem;
