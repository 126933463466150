import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles<{ background: string }>({ name: 'ApiAdvertising' })((theme, { background }) => ({
    root: {
        background: `url(${background}) center / cover no-repeat #212121`,
        padding: '160px 64px',
        gap: 120,
        borderRadius: 32,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            padding: '40px 24px 24px 24px'
        }
    },
    content: {
        maxWidth: 1280,
        margin: 'auto',
        display: 'grid',
        gridTemplateAreas: `
            "title item-a"
            "title item-b"
            "button item-c"
       `,
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto',
        rowGap: 40,
        columnGap: 32,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gridTemplateAreas: `
            "title"
            "item-a"
            "item-b"
            "item-c"
            "button"
       `,
            gridTemplateColumns: '1fr',
            rowGap: 64
        }
    },
    title: {
        gridArea: 'title'
    },
    buttonWrapper: {
        gridArea: 'button',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start'
    },
    itemA: {
        gridArea: 'item-a'
    },
    itemB: {
        gridArea: 'item-b'
    },
    itemC: {
        gridArea: 'item-c'
    },
    button: {
        height: 56,
        padding: '20px 24px',
        borderRadius: 16,
        background: 'linear-gradient(249deg, #FF8B61 7.18%, #D92E7F 172.88%)',
        color: '#FFF',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '150%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            width: '100%'
        }
    }
}));
