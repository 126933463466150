import React, { useCallback } from 'react';

import { IconButton } from '@mui/material';

import useCopyToClipboard from 'pages/hooks/use-copy-to-clipboard';

import CopyIcon from './assets/copy.svg';
import CopyDoneIcon from './assets/copy-done.svg';
import useStyles from './styles';

interface Props {
    value: string | number;
    className?: string;
}

const CopyButton: React.FC<Props> = ({ value, className }) => {
    const { classes, cx } = useStyles();
    const [copy, isCopied] = useCopyToClipboard();
    const handleClick = useCallback(() => copy(String(value)), [copy, value]);

    return (
        <IconButton disableRipple onClick={handleClick} className={cx(classes.root, className)}>
            {isCopied ? <CopyDoneIcon /> : <CopyIcon />}
        </IconButton>
    );
};

export default CopyButton;
