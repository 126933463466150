import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { Button, CircularProgress } from '@mui/material';

import useStores from 'hooks/use-stores';
import BlogPostItem from 'pages/landing/v2/Main/components/Blog/components/BlogPostItem';
import useIsMobile from 'pages/landing/v2/Main/hooks/use-is-mobile';

import useStyles from './styles';

interface Props {
    className?: string;
}

const Blog: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    const { blogStore } = useStores();

    useEffect(() => {
        blogStore.loadPosts();
    }, [blogStore]);

    const isMobile = useIsMobile();

    const browseAllButton = useMemo(
        () => (
            <Button href="https://vulners.com/blog" target="_blank" variant="contained" className={classes.button}>
                {'Browse all'}
            </Button>
        ),
        [classes.button]
    );

    return (
        <div className={cx(classes.root, className)}>
            <div className={classes.header}>
                <h1 className={classes.title}>{'Blog'}</h1>
                {isMobile ? null : browseAllButton}
            </div>

            <div className={classes.content}>
                {blogStore.isLoading ? (
                    <CircularProgress />
                ) : (
                    blogStore.posts.map(({ title, date, image, link }, index) => (
                        <BlogPostItem key={index} title={title} image={image} date={date} link={link} />
                    ))
                )}
            </div>

            {isMobile ? browseAllButton : null}
        </div>
    );
};

export default observer(Blog);
