import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'InfoGraphicItem' })((theme) => ({
    root: {
        padding: 32,
        width: 440,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 24,
        border: '1px solid #DEE1E2',
        gap: 20,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            width: '296px',
            flexDirection: 'column'
        }
    },
    icon: {
        width: 40,
        height: 40
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 4
    },
    value: {
        color: '#232323',
        fontSize: 36,
        fontWeight: 700,
        lineHeight: '120%'
    },
    unit: {
        color: '#232323',
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '100%'
    },
    description: {
        color: '#8E8E8E',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '140%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            textAlign: 'center'
        }
    }
}));
