import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'InsightsItem' })({
    primary: {
        color: '#FAFAFA'
    },
    secondary: {
        color: '#8E8E8E'
    }
});
