import React from 'react';

import useStyles from './styles';

interface Props {
    className?: string;
}

const Logo: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    return (
        <a href="/" className={cx(classes.root, className)}>
            <img src="/static/img/logo_small.svg" className={classes.image} alt="logo" />
        </a>
    );
};

export default Logo;
