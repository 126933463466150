import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'CopyButton' })({
    root: {
        padding: 12,
        borderRadius: 12,
        color: '#FFF',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(236, 236, 236, 0.20)',
        transition: 'all 0.2s',
        ['&:hover']: {
            borderColor: '#D3D3D3'
        },
        ['&:active']: {
            borderColor: 'transparent',
            background: 'rgba(236, 236, 236, 0.20)'
        },
        ['& svg']: {
            width: 16,
            height: 16
        }
    }
});
