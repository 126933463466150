import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Blog' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 64,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 40
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        margin: 0,
        color: '#232323',
        fontSize: 48,
        fontWeight: 700,
        lineHeight: '120%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 32,
            textAlign: 'start'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'stretch',
        gap: 32,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            flexDirection: 'column'
        }
    },
    button: {
        boxShadow: 'none !important',
        height: 56,
        padding: '20px 24px',
        color: '#FFF',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '150%',
        borderRadius: 16,
        background: '#232323 !important',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 16,
            lineHeight: '100%'
        }
    }
}));
