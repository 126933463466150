import { makeStyles } from 'tss-react/mui';

export default makeStyles<{ gap: number }>({ name: 'Carousel' })((_, { gap }) => ({
    container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'hidden',
        overflowX: 'auto',
        ['&::-webkit-scrollbar']: {
            display: 'none'
        },
        maskImage: 'linear-gradient(to right, transparent 0%, white 10% 90%, transparent 100%)'
    },
    track: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        gap,
        width: 'auto'
    },
    wrapper: {
        flex: 1
    }
}));
