import React from 'react';

import useStyles from './styles';

interface Props {
    index: number;
    title: React.ReactNode;
    selected?: boolean;
    className?: string;
    onClick?: () => void;
}

const CaseItem: React.FC<Props> = ({ index, title, selected, onClick, className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, { [classes.rootSelected]: selected }, className)} onClick={onClick}>
            <span className={cx(classes.index, { [classes.indexSelected]: selected })}>{index}</span>
            <span className={cx(classes.title, { [classes.titleSelected]: selected })}>{title}</span>
        </div>
    );
};

export default CaseItem;
