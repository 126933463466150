import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ApiAdvertisingItem' })({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    icon: {
        marginBottom: 8
    },
    title: {
        color: '#FFF',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '150%'
    },
    description: {
        color: '#FFF',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '150%',
        opacity: 0.65
    }
});
