import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'MenuItem' })({
    root: {
        color: '#FAFAFA',
        fontSize: 16,
        fontWeight: 400,
        borderTop: '1px solid #8E8E8E',
        borderBottom: '1px solid #8E8E8E',
        margin: '-1px 0'
    },
    text: {
        font: 'inherit',
        color: 'inherit'
    },
    title: {
        position: 'relative'
    },
    accent: {
        ['&::after']: {
            content: '""',
            position: 'absolute',
            width: 8,
            height: 8,
            borderRadius: 4,
            background: '#FF8B61',
            top: 0,
            right: -12
        }
    }
});
