import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'BlogPostItem' })({
    root: {
        textDecoration: 'none',
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        flex: 1,
        width: 405,
        maxWidth: '100%',
        boxSizing: 'border-box',
        borderRadius: 24,
        border: '1px solid #DEE1E2',
        background: '#FFF'
    },
    image: {
        borderRadius: 8,
        maxHeight: 200,
        objectFit: 'contain'
    },
    title: {
        margin: 0,
        color: '#232323',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '150%',
        marginBottom: 'auto'
    },
    date: {
        marginRight: 'auto',
        padding: '8px 12px',
        borderRadius: 8,
        background: '#F8F8F8',
        color: '#8E8E8E',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '150%'
    }
});
