import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Customers' })((theme) => ({
    carousel: {
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            paddingLeft: 248
        }
    }
}));
