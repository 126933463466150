import { makeStyles } from 'tss-react/mui';

import { chipClasses } from '@mui/material';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Templates' })((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 24,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 8,
            justifyContent: 'flex-start'
        }
    },
    chip: {
        height: 50,
        borderRadius: 25,
        background: '#fff',
        padding: '16px 24px',
        border: '1px solid #DEE1E2',
        boxShadow: 'none !important',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            height: 32,
            borderRadius: 16,
            padding: '10px 12px'
        },
        [`& .${chipClasses.label}`]: {
            padding: 0,
            color: '#232323',
            opacity: 0.75,
            fonSize: 16,
            fontWeight: 500,
            lineHeight: '16px',
            [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
                fonSize: 12,
                fontWeight: 500,
                lineHeight: '12px'
            }
        }
    }
}));
