import React from 'react';

import useStyles from './styles';

interface Props {
    image: string;
    description: string;
    className?: string;
}

const FeatureCard: React.FC<Props> = ({ image, description, className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <img className={classes.image} src={image} alt="feature image" />
            <p className={classes.description}>{description}</p>
        </div>
    );
};

export default FeatureCard;
