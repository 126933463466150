import React from 'react';

import useStyles from './styles';

interface Props {
    primary: string;
    secondary: string;
    className?: string;
}

const InsightsItem: React.FC<Props> = ({ primary, secondary, className }) => {
    const { classes, cx } = useStyles();

    return (
        <div className={cx(classes.container, className)}>
            <span className={classes.primary}>{primary}</span>
            <span className={classes.secondary}>{secondary}</span>
        </div>
    );
};

export default InsightsItem;
