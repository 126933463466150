import React from 'react';

import useStyles from './styles';

interface Props {
    logo: React.ReactNode;
    className?: string;
}

const CustomerItem: React.FC<Props> = ({ logo, className }) => {
    const { classes, cx } = useStyles();
    return <div className={cx(classes.root, className)}>{logo}</div>;
};

export default CustomerItem;
