import { observer } from 'mobx-react';
import React, { isValidElement, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import useStyles from './styles';
import { animateAsync, easeInOutCubic, first, last, wait } from './utils';

interface Props {
    gap?: number;
    duration?: number;
    interval?: number;
    direction?: 'ltr' | 'rtl';
    className?: string;
}

const Carousel: React.FC<Props> = ({
    interval = 5_000,
    gap = 16,
    duration = 1_000,
    direction = 'ltr',
    className,
    children
}) => {
    const { classes, cx } = useStyles({ gap });

    const createItem = useCallback((node: React.ReactNode) => ({ node, key: uuid() }), []);
    const prepare = useCallback((children: React.ReactNode) => [children].flat().map(createItem), [createItem]);

    const trackRef = useRef<HTMLDivElement | null>(null);
    const animationPromiseRef = useRef<Promise<void>>(Promise.resolve());

    const [items, setItems] = useState(prepare(children));
    useEffect(() => {
        void animationPromiseRef.current.then(() => setItems(prepare(children)));
    }, [children, prepare]);

    const onTick = useCallback(async () => {
        const track = trackRef.current;
        if (!track?.firstElementChild || !track?.lastElementChild || track?.childElementCount < 2) return;

        const animate = async (property: string, offset: number, callback: () => void) => {
            track.style.setProperty('width', `${track.clientWidth}px`);
            track.style.setProperty(property, `0px`);
            setItems((items) => [createItem(last(items).node), ...items, createItem(first(items).node)]);
            await animateAsync({
                duration,
                easing: easeInOutCubic,
                onProgress: (progress) => track.style.setProperty(property, `${progress * offset}px`)
            });
            callback();
            track.style.setProperty('width', `${track.clientWidth}px`);
            track.style.setProperty(property, `0px`);
        };

        if (direction === 'ltr') {
            const offset = track.firstElementChild.clientWidth + gap;
            animationPromiseRef.current = animate('right', offset, () => setItems((items) => items.slice(2)));
        }
        if (direction === 'rtl') {
            const offset = track.lastElementChild.clientWidth + gap;
            animationPromiseRef.current = animate('left', offset, () => setItems((items) => items.slice(0, -2)));
        }

        return animationPromiseRef.current;
    }, [createItem, direction, duration, gap]);

    useEffect(() => {
        let done = false;
        const loop = async () => {
            while (done === false) {
                await wait(interval);
                await onTick();
            }
        };

        void loop();
        return () => {
            done = true;
        };
    }, [onTick, interval]);

    return (
        <div className={cx(classes.container, className)}>
            <div className={classes.track} ref={trackRef}>
                {items
                    .filter(({ node }) => isValidElement(node))
                    .map(({ node, key }) => (
                        <span key={key} className={classes.wrapper}>
                            {node}
                        </span>
                    ))}
            </div>
        </div>
    );
};
export default observer(Carousel);
