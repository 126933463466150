import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'FeatureCard' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        padding: 24,
        borderRadius: 24,
        border: '1px solid #DEE1E2',
        background: '#FFF',
        maxWidth: 357,
        boxSizing: 'border-box',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            width: '100%',
            maxWidth: 'unset'
        }
    },
    image: {
        objectFit: 'scale-down',
        borderRadius: 8
    },
    description: {
        margin: 0,
        color: '#232323',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: '27px'
    }
}));
