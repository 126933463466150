import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'SubMenu' })({
    root: {
        width: '100%'
    },
    title: {
        color: '#8E8E8E',
        fontSize: 12,
        fontWeight: 700,
        textTransform: 'uppercase',
        background: 'transparent'
    }
});
