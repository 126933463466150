import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Search' })((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 95,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            gap: 16
        }
    },
    title: {
        margin: 0,
        fontSize: 52,
        fontWeight: 700,
        lineHeight: '120%',
        color: '#232323',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 40,
            lineHeight: '48px'
        }
    },
    accent: {
        background: 'linear-gradient(249deg, #FF8B61 7.18%, #D92E7F 172.88%)',
        backgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
    },
    regular: {
        whiteSpace: 'nowrap',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            whiteSpace: 'normal'
        }
    },
    content: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: 110,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            flexDirection: 'column',
            gap: 32
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    text: {
        order: 1,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            order: 2
        }
    },
    templates: {
        order: 2,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            order: 1
        }
    },
    description: {
        marginTop: 24,
        marginBottom: 48,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        opacity: 0.65,
        color: '#232323',
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '150%',
        ['& p']: {
            margin: 0
        },
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 18
        }
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
        gap: 16
    },
    login: {
        height: 56,
        boxShadow: 'none',
        padding: '20px 24px',
        borderRadius: 16,
        background: '#232323 !important',
        color: '#FFF',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '150%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '100%'
        }
    },
    demo: {
        height: 56,
        boxShadow: 'none',
        padding: '20px 24px',
        borderRadius: 16,
        background: 'transparent !important',
        border: '2px solid #232323 !important',
        color: '#232323',
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '150%',
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '100%'
        }
    }
}));
