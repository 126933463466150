import React from 'react';

import { CircularProgress } from '@mui/material';

import useStyles from './styles';

interface Props {
    icon: React.ReactNode;
    value: number;
    unit?: string;
    description: string;
    className?: string;
    loading?: boolean;
}

const InfoGraphicItem: React.FC<Props> = ({ icon, value, unit, description, loading, className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            <span className={classes.icon}>{icon}</span>
            {loading ? (
                <CircularProgress />
            ) : (
                <span className={classes.container}>
                    <span className={classes.value}>{value}</span>
                    <span className={classes.unit}>{unit}</span>
                </span>
            )}
            <span className={classes.description}>{description}</span>
        </div>
    );
};

export default InfoGraphicItem;
