import React from 'react';

import AdobeIcon from './assets/adobe.svg';
import AlertLogicIcon from './assets/alert-logic.svg';
import CloudLinuxIcon from './assets/cloud-linux.svg';
import CybersicherheitIcon from './assets/cybersicherheit.svg';
import InavistieIcon from './assets/inavistie.svg';
import PlayrixIcon from './assets/playrix.svg';
import RingCentralIcon from './assets/ring-central.svg';
import SemrushIcon from './assets/semrush.svg';
import Soft2BetIcon from './assets/soft-2-bet.svg';
import VulcanIcon from './assets/vulcan.svg';
import CustomerItem from './components/CustomerItem';
import useStyles from './styles';

import Carousel from '../Carousel';

interface Props {
    className?: string;
}

const Customers: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    return (
        <Carousel className={cx(classes.carousel, className)} gap={20} direction="rtl">
            <CustomerItem logo={<AlertLogicIcon />} />
            <CustomerItem logo={<CloudLinuxIcon />} />
            <CustomerItem logo={<CybersicherheitIcon />} />
            <CustomerItem logo={<InavistieIcon />} />
            <CustomerItem logo={<PlayrixIcon />} />
            <CustomerItem logo={<RingCentralIcon />} />
            <CustomerItem logo={<SemrushIcon />} />
            <CustomerItem logo={<Soft2BetIcon />} />
            <CustomerItem logo={<AdobeIcon />} />
            <CustomerItem logo={<VulcanIcon />} />
        </Carousel>
    );
};

export default Customers;
