import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import useStores from 'hooks/use-stores';

import SearchBar from './components/SearchBar';
import Templates from './components/Templates';
import useStyles from './styles';

interface Props {
    className?: string;
}

const Search: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    const { userStore } = useStores();
    return (
        <div className={cx(classes.root, className)}>
            <SearchBar />
            <div className={classes.content}>
                <div className={cx(classes.column, classes.text)}>
                    <h1 className={classes.title}>
                        <span className={classes.accent}>{'All-in-one'}</span>
                        <span className={classes.regular}>{' vulnerability intelligence'}</span>
                    </h1>
                    <div className={classes.description}>
                        <p>{'Prioritize remediation efforts with rich context beyond the CVSS.'}</p>
                        <p>{'Drive offensive and defensive efforts with the latest updates on exploits.'}</p>
                        <p>
                            {
                                'Integrate vulnerability intelligence delivered in normalized and correlated machine-readable format.'
                            }
                        </p>
                    </div>
                    <div className={classes.actions}>
                        {userStore.user.isAnonymous ? (
                            <Button variant="contained" component={Link} to={'/login'} className={classes.login}>
                                {'Sign in'}
                            </Button>
                        ) : null}
                        <Button variant="outlined" component={Link} to={'/contacts'} className={classes.demo}>
                            {'Book live demo'}
                        </Button>
                    </div>
                </div>
                <div className={cx(classes.column, classes.templates)}>
                    <Templates />
                </div>
            </div>
        </div>
    );
};

export default observer(Search);
