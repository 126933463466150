import React from 'react';
import { Link } from 'react-router-dom';

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import useStyles from './styles';

interface Props {
    title: string;
    description: string;
    link: string;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    className?: string;
}

const SubMenuItem: React.FC<Props> = ({ title, description, icon, link, onClick, className }) => {
    const { classes } = useStyles();

    return (
        <ListItemButton component={Link} to={link} className={className} onClick={onClick}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText
                primary={title}
                secondary={description}
                classes={{ primary: classes.primary, secondary: classes.secondary }}
            />
        </ListItemButton>
    );
};

export default SubMenuItem;
