import fullTextSearchInTheDatabase from './full-text-search-in-the-database';
import getFullDocumentById from './get-full-document-by-id';
import getVulnerabilitiesByCpe from './get-vulnerabilities-by-cpe';
import getVulnerabilitiesByProductAndVersion from './get-vulnerabilities-by-product-and-version';
import searchExploits from './search-exploits';

import { Case } from '../types';

const cases: Case[] = [
    getFullDocumentById,
    fullTextSearchInTheDatabase,
    getVulnerabilitiesByCpe,
    getVulnerabilitiesByProductAndVersion,
    searchExploits
];

export default cases;
