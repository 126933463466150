import { observer } from 'mobx-react';
import React, { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { AccountCircleOutlined } from '@mui/icons-material';
import { Button, Drawer, IconButton } from '@mui/material';

import { SEARCH_TYPE_COOKIE } from 'app/constants';
import useStores from 'hooks/use-stores';
import { SearchType } from 'main/components/SearchBar';
import Logo from 'pages/landing/v2/Main/components/Logo';
import { setCookie } from 'utils/cookies';

import BurgerIcon from './assets/burger.svg';
import CrossIcon from './assets/cross.svg';
import MenuItem from './components/MenuItem';
import SubMenu from './components/SubMenu';
import SubMenuGroup from './components/SubMenuGroup';
import SubMenuItem from './components/SubMenuItem';
import useStyles from './styles';

interface Props {
    className?: string;
}

const MenuDesktop: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();

    const history = useHistory();
    const { userStore, searchStore } = useStores();
    const [isOpen, toggleIsOpen] = useReducer((state) => !state, false);

    const redirectToSearchWith = useCallback(
        (type: SearchType) => (event: React.MouseEvent) => {
            event.preventDefault();
            const { query, version, software, state } = searchStore;
            searchStore.setParameters({ type, query, version, software, state });
            setCookie(SEARCH_TYPE_COOKIE, type, { ['max-age']: Number.MAX_SAFE_INTEGER });
            history.push('/search');
        },
        [history, searchStore]
    );

    return (
        <>
            <IconButton onClick={toggleIsOpen} className={cx(classes.root, className)}>
                <BurgerIcon />
            </IconButton>
            <Drawer variant="temporary" open={isOpen} classes={{ paper: classes.paper }}>
                <div className={classes.header}>
                    <Logo />
                    <IconButton onClick={toggleIsOpen} className={classes.close}>
                        <CrossIcon />
                    </IconButton>
                </div>
                <div className={classes.menu}>
                    <MenuItem title={'Applications'}>
                        <SubMenu>
                            <SubMenuGroup title="Search">
                                <SubMenuItem
                                    title="Basic Search"
                                    description="Search using prebuilt filters"
                                    link="/search"
                                    onClick={redirectToSearchWith(SearchType.elastic)}
                                />
                                <SubMenuItem
                                    title="Lucene Search"
                                    description="Use powerful query language"
                                    link="/search"
                                    onClick={redirectToSearchWith(SearchType.simple)}
                                />
                                <SubMenuItem
                                    title="Product Search"
                                    description="Search by product and version"
                                    link="/search"
                                    onClick={redirectToSearchWith(SearchType.product)}
                                />
                            </SubMenuGroup>
                            <SubMenuGroup title="Dashboards">
                                <SubMenuItem
                                    title="Scanner dashboard"
                                    description="Linux and Windows scan results"
                                    link="/scanner"
                                />
                                <SubMenuItem
                                    title="Perimeter Control Dashboard"
                                    description="Perimeter Scan Results"
                                    link="/vscanner"
                                />
                            </SubMenuGroup>
                            <SubMenuGroup title="Notifications">
                                <SubMenuItem
                                    title="Email Notifications"
                                    description="Email Notifications"
                                    link="/email"
                                />
                                <SubMenuItem
                                    title="Webhook Notifications"
                                    description="Configure webhook notifications"
                                    link="/webhook"
                                />
                            </SubMenuGroup>
                        </SubMenu>
                    </MenuItem>
                    <MenuItem title={'Our solutions'} accent>
                        <SubMenu>
                            <SubMenuGroup title="Offensive Security">
                                <SubMenuItem
                                    title="Perimeter Control Tool"
                                    description="24/7 watch for open ports and vulnerable services behind them on your network"
                                    link="/perimeter-control"
                                />
                                <SubMenuItem
                                    title="Lucene Search"
                                    description="Use powerful query language"
                                    link="/search"
                                    onClick={redirectToSearchWith(SearchType.simple)}
                                />
                                <SubMenuItem
                                    title="Vulnerabilities Intelligence"
                                    description="Normalized machine-readable feed of latest CVEs, exploits, 0days, news and advisories"
                                    link="/feed"
                                />
                            </SubMenuGroup>
                            <SubMenuGroup title="Vulnerability Assessment">
                                <SubMenuItem
                                    title="Linux Scanner"
                                    description="Detect all known vulnerabilities in Linux environment with minimum false positives"
                                    link="/linux"
                                />
                                <SubMenuItem
                                    title="Windows Scanner"
                                    description="Vulnerabilities assessment for Windows environment with minimum false positives"
                                    link="/windows"
                                />
                            </SubMenuGroup>
                            <SubMenuGroup title="Product Development">
                                <SubMenuItem
                                    title="Developer SDK"
                                    description="Powerful API to power your product with vulnerabilities intelligence"
                                    link="/sdk"
                                />
                            </SubMenuGroup>
                        </SubMenu>
                    </MenuItem>
                    <MenuItem title={'Resources'}>
                        {
                            <SubMenu>
                                <SubMenuGroup title="Resources">
                                    <SubMenuItem
                                        title="Statistics & Sources"
                                        description="Vulnerability analysis of Vulners database"
                                        link="/stats"
                                    />
                                    <SubMenuItem
                                        title="Plugins"
                                        description="Integrations to power your security tools with vulnerabilities intelligence"
                                        link="/plugins"
                                    />
                                </SubMenuGroup>
                                <SubMenuGroup title="Documentation">
                                    <SubMenuItem
                                        title="API docs"
                                        description="API documentation for developers and integrations"
                                        link="/docs/"
                                    />
                                    <SubMenuItem
                                        title="F.A.Q"
                                        description="Most frequently asked questions about Vulners"
                                        link="/faq"
                                    />
                                </SubMenuGroup>
                                <SubMenuGroup title="Other">
                                    <SubMenuItem
                                        title="Blog"
                                        description="Articles about vulnerabilities, exploits, news and advisories"
                                        link="/blog/"
                                    />
                                    <SubMenuItem
                                        title="Glossary"
                                        description="Glossary of terms used in Vulners"
                                        link="/glossary/"
                                    />
                                </SubMenuGroup>
                            </SubMenu>
                        }
                    </MenuItem>
                    <MenuItem title={'Contacts'} link="/contacts" />
                    <MenuItem title={'Pricing'} link="/pricing" />
                </div>
                <div className={classes.actions}>
                    {userStore.isAuthorized ? (
                        <Button
                            variant="outlined"
                            component={Link}
                            to={'/userinfo'}
                            className={classes.login}
                            startIcon={<AccountCircleOutlined />}
                        >
                            {userStore.user.username}
                        </Button>
                    ) : (
                        <Button variant="outlined" component={Link} to={'/login'} className={classes.login}>
                            {'Sign in'}
                        </Button>
                    )}
                    <Button variant="contained" fullWidth className={classes.demo} component={Link} to={'/contacts'}>
                        {'Book a live demo'}
                    </Button>
                </div>
            </Drawer>
        </>
    );
};

export default observer(MenuDesktop);
