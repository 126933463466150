import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'MenuItem' })({
    root: {
        position: 'relative',
        padding: '8px 12px',
        borderRadius: 12,
        color: '#232323',
        fontSize: 16,
        lineHeight: '16px',
        fontWeight: 500,
        transition: 'all 0.2s',
        textDecoration: 'none',
        ['&:hover']: {
            background: '#F8F8F8'
        }
    },
    accent: {
        position: 'absolute',
        width: 8,
        height: 8,
        borderRadius: 4,
        background: '#FF8B61',
        top: 5,
        right: 3
    },
    popper: {
        maxWidth: '100vw',
        width: '100vw'
    },
    tooltip: {
        marginTop: '33px !important',
        maxWidth: '100%',
        width: '100%',
        boxSizing: 'border-box',
        padding: '56px 64px 64px 64px',
        background: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
