import React from 'react';

import InsightsItem from './components/InsightsItem';
import useStyles from './styles';

interface Props {
    className?: string;
}

const Insights: React.FC<Props> = ({ className }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.container, className)}>
            <h1 className={classes.title}>{'Get key vulnerability intelligence insights'}</h1>
            <div className={classes.list}>
                <InsightsItem
                    primary="References"
                    secondary="Documents in the database that reference the vulnerability"
                />
                <InsightsItem
                    primary="Is Wild Exploited"
                    secondary="Vulnerability included in the CISA KEV list or there are other exploitation evidence"
                />
                <InsightsItem
                    primary="Social networks activity"
                    secondary="Actively discussed vulnerabilities often pose more risk"
                />
                <InsightsItem
                    primary="Vulners AI Score"
                    secondary="Vulnerability impact estimation with our proprietary machine learning model"
                />
                <InsightsItem
                    primary="AI Tags"
                    secondary="ChatGPT-powered classification of vulnerabilities for easy search and analysis"
                />
                <InsightsItem
                    primary="Exploits PoC"
                    secondary="Interlinked information about exploits PoC on popular resources"
                />
                <InsightsItem
                    primary="Documents Hisory"
                    secondary="We track and store all changes to CVE and other documents in the database"
                />
                <InsightsItem primary="CVSS" secondary="Vector and Base Score assigned by CNA and NVD" />
                <InsightsItem
                    primary="EPSS"
                    secondary="Probability of vulnerability exploitation in the next 30 days"
                />
            </div>
        </div>
    );
};

export default Insights;
