import { default as api } from './api.sh?raw';
import { default as python } from './main.py?raw';
import { default as response } from './response.json?raw';

import { Case } from '../../types';

const data: Case = {
    title: 'Get vulnerabilities by CPE',
    data: { api, python, response }
};

export default data;
