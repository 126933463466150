import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'TabItem' })({
    root: {
        cursor: 'pointer',
        padding: '8px 20px',
        textAlign: 'center',
        color: '#8e8e8e',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '16px',
        borderRadius: 32,
        transition: 'all 0.2s'
    },
    selected: {
        background: '#393939',
        color: '#fff'
    }
});
