import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'MenuDesktop' })({
    root: {
        padding: 8,
        borderRadius: 12,
        color: '#2D2D2D',
        background: '#FFF',
        transition: 'all 0.2s',
        ['&:active']: {
            background: '#FFF'
        },
        ['& svg']: {
            width: 24,
            height: 24
        }
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: '#000',
        color: '#FAFAFA',
        width: '100%',
        padding: '0 20px 40px 20px',
        boxSizing: 'border-box'
    },
    close: {
        padding: 8,
        borderRadius: 12,
        color: '#FFF',
        background: 'rgba(236, 236, 236, 0.20)',
        transition: 'all 0.2s',
        ['&:active']: {
            background: 'rgba(236, 236, 236, 0.20)'
        },
        ['& svg']: {
            width: 24,
            height: 24
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 0'
    },
    menu: {},
    actions: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    demo: {
        height: 56,
        padding: '20px 24px',
        borderRadius: 16,
        background: 'linear-gradient(249deg, #FF8B61 7.18%, #D92E7F 172.88%)',
        color: '#FFF',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '150%'
    },
    login: {
        height: 56,
        boxShadow: 'none',
        padding: '20px 24px',
        borderRadius: 16,
        background: 'transparent !important',
        border: '2px solid #FAFAFA !important',
        color: '#FAFAFA',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '150%'
    }
});
