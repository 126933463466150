export const easeInOutCubic = (x: number) => (x < 0.5 ? 4 * Math.pow(x, 3) : 1 - Math.pow(-2 * x + 2, 3) / 2);

interface AnimateOptions {
    duration: number;
    easing?: (x: number) => number;
    onProgress: (progress: number) => void;
}

export const createPromise = <T = void>() => {
    let resolve: (value: T) => void = () => {};
    let reject: (reason: Error) => void = () => {};

    const promise = new Promise<T>((resolvePromise, rejectPromise) => {
        resolve = resolvePromise;
        reject = rejectPromise;
    });

    return { promise, resolve, reject };
};

export const wait = (delay: number) => {
    const { promise, resolve } = createPromise();
    setTimeout(() => resolve(), delay);
    return promise;
};

export const last = <T>(list: T[]) => list[list.length - 1];
export const first = <T>(list: T[]) => list[0];

export const animateAsync = (options: AnimateOptions) => {
    let start = 0;
    const { duration, onProgress, easing } = options;

    const { promise, resolve } = createPromise();

    const inner = (time: number) => {
        start ||= time;
        const fraction = (time - start) / duration;
        if (fraction >= 0 && fraction <= 1) {
            const progress = easing ? easing(fraction) : fraction;
            onProgress(progress);
            window.requestAnimationFrame(inner);
        } else {
            resolve();
        }
    };

    window.requestAnimationFrame(inner);
    return promise;
};
