import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'SubMenu' })({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    action: {
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        gap: 20,
        padding: 16,
        borderRadius: 16,
        border: '1px solid #DEE1E2',
        width: '100%'
    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4
    },
    title: {
        color: '#232323',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '20px'
    },
    subtitle: {
        color: '#8E8E8E',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '26px'
    }
});
