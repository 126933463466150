import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'SubMenu' })({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'flex-start'
    },
    title: {
        padding: 16,
        color: '#8E8E8E',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '16px',
        textTransform: 'uppercase'
    }
});
