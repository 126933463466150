import { makeStyles } from 'tss-react/mui';

import { MOBILE_BREAK_POINT } from 'pages/landing/v2/Main/constants';

export default makeStyles({ name: 'Main' })((theme) => ({
    root: {
        backgroundColor: '#F0F3F4',
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 1280,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            minWidth: 'auto',
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    section: {
        margin: 'auto',
        boxSizing: 'border-box',
        maxWidth: 1280,
        marginBottom: 240,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            marginBottom: 120
        }
    },
    stretch: {
        width: '100%',
        maxWidth: '100%'
    },
    gutterless: {
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            marginLeft: -16,
            marginRight: -16,
            width: 'calc(100% + 32px)',
            maxWidth: 'calc(100% + 32px)'
        }
    },
    header: {
        marginBottom: 0,
        marginTop: 0
    },
    divider: {
        borderColor: '#DEE1E2',
        marginBottom: 56,
        [theme.breakpoints.down(MOBILE_BREAK_POINT)]: {
            marginBottom: 16
        }
    },
    footer: {
        backgroundColor: '#F0F3F4',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0
    }
}));
