import React, { PropsWithChildren } from 'react';

import useStyles from './styles';

interface Props {
    title?: string;
    className?: string;
}

const SubMenuGroup: React.FC<PropsWithChildren<Props>> = ({ title, className, children }) => {
    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root, className)}>
            {title ? <span className={classes.title}>{title}</span> : null}
            {children}
        </div>
    );
};

export default SubMenuGroup;
