import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'CustomerItem' })({
    root: {
        display: 'flex',
        width: 228,
        height: 120,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
        border: '1px solid #DEE1E2',
        boxSizing: 'border-box'
    }
});
